import { render, staticRenderFns } from "./CustomerTasks.vue?vue&type=template&id=1cbbb7fa&"
import script from "./CustomerTasks.vue?vue&type=script&lang=js&"
export * from "./CustomerTasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports